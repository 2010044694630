var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseInput',{ref:"emailInput",attrs:{"value":_vm.value,"max-length":255,"validation-id":"email_validation","type":"email","name":"email","data-vv-validate-on":"blur","automation-id":_vm.getAutomationId('email'),"validation-rules":{
		required: true,
		email: true,
		isSameEmail: { currentEmail: _vm.currentEmail, requiresCurrentEmailCheck: _vm.requiresCurrentEmailCheck }
	},"validation-messages":{
		required: _vm.$t('error.emailEmpty'),
		email: _vm.$t('error.malformedEmail'),
		isSameEmail: _vm.$t('error.sameEmail')
	},"label":_vm.label},on:{"input":_vm.updateEmail,"keyup":_vm.onKeyUp}})
}
var staticRenderFns = []

export { render, staticRenderFns }