<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<div id="memberCard" :automation-id="getAutomationId('memberCard')">
		<h2 class="title">{{ $t('title') }}</h2>
		<p>{{ $t('subtitle') }}</p>
		<!--TODO Need brand regional and province logic -->
		<BaseAlert v-if="showCardMessage" class="no-pad" variant="warning" alternate>
			<h5 class="info-text font-weight-bold">{{ headingText }}</h5>
			<div class="info-text">{{ $t('repatriation.desc') }}</div>
			<ul class="info-text">
				<li>{{ $t('repatriation.bulletOne') }}</li>
				<li>{{ $t('repatriation.bulletTwo') }}</li>
				<li>{{ $t('repatriation.bulletThree') }}</li>
			</ul>
		</BaseAlert>
		<MemberCardInfo :member-info="memberInfo" :automation-id="getAutomationId('memberCard')" />
		<div class="mb-4">
			<BRow no-gutters>
				<BCol cols="12" md="auto">
					<BaseButton
						:label="$t('label.print')"
						class="mb-3 mb-md-0 mr-3"
						:aria-label="$t('label.print')"
						:automation-id="getAutomationId('memberCardPrint')"
						:data-tracking-id="getAutomationId('memberCardPrint')"
						type="submit"
						variant="primary"
						@click="downloadPdf()"
					/>
				</BCol>
				<BCol>
					<BaseButton
						:label="$t('label.email')"
						:automation-id="getAutomationId('memberCardEmail')"
						:data-tracking-id="getAutomationId('memberCardEmail')"
						variant="primary"
						@click="navigateToShareEmail()"
					/> </BCol
			></BRow>
		</div>
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
// This component renders Main Member card component with the members details on the card.
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
import * as Routes from '@/constants/Routes.js';
import BaseButton from '@/components/common/base/BaseButton';
import ContactInfo from '@/models/ContactInfo';
import MemberCardInfo from '@/components/manage-your-account/member-card/MemberCardInfo.vue';
import BaseAlert from '@/components/common/alert/BaseAlert.vue';
/**
 * This component is responsible to render a member table.
 */
// @vue/component
@Component({
	name: 'MemberCard',
	components: {
		BRow,
		BCol,
		BaseButton,
		MemberCardInfo,
		BaseAlert
	},
	mixins: [IdMixin],
	props: {
		memberInfo: {
			type: Object,
			default: null
		}
	}
})
export default class MemberCard extends Vue {
	emailList = [''];
	national = false;
	brand = 'GEN';
	showCardMessage = false;

	created() {
		const { national, brand } = JSON.parse(sessionStorage.getItem('securityPolicy'));
		this.brand = brand ? brand : 'GEN';
		this.national = national;
		this.isShowCardMessage();
	}

	async isShowCardMessage() {
		const memberAddress = await ContactInfo.getUserInfo(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale
		);
		if (
			(this.national && this.brand === 'SBC' && memberAddress?.province !== 'SK') ||
			(this.national &&
				this.brand === 'MBC' &&
				!['PE', 'NB', 'NS', 'NF', 'NL'].includes(memberAddress?.province))
		) {
			this.showCardMessage = true;
		}
	}

	// Navigate to the share member card page.
	navigateToShareEmail() {
		this.$router.push({ name: Routes.SHARE_MEMBER_CARD });
	}

	// Emit a submit event to download a pdf
	downloadPdf() {
		this.$emit('submit');
	}

	get headingText() {
		if (this.brand === 'SBC') {
			return this.$t('repatriation.headingSBC');
		} else {
			return this.$t('repatriation.headingMBC');
		}
	}
}
</script>

<style lang="scss" scoped>
// TODO: remove global padding/margin for ul and fix site wide
ul {
	margin: revert;
	padding: revert;
}
.title {
	font-size: $header-main-font-size;
	margin-bottom: 0;
	@media (max-width: 500px) {
		line-height: 1.3em;
	}
}
.info-text {
	font-family: $font-family-regular;
	font-weight: 100;
	font-size: 17px;
}
.subtitle {
	font-size: $header-sub-font-size;
	font-family: $body-small-font-family;
	font-weight: $body-small-font-weight;
	margin-bottom: 25px;
}
//css for print button
.btn.btn-primary {
	padding: 0.6rem 1.1rem;
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
	"en": {
        "title": "Need a copy of your member card?",
        "subtitle": "Print, download or share a copy of your card with other people on your plan.",
        "label": {
            "print": "Print or download card",
            "email": "Email card"
		},
		"repatriation": {
			"headingMBC": "Your Medavie Blue Cross card",
			"headingSBC": "Your Saskatchewan Blue Cross card",
			"desc": "Please show your new ID card below next time you visit a current or new:",
			"bulletOne": "Pharmacy",
			"bulletTwo": "Dentist",
			"bulletThree": "Extended Health Provider (for example physiotherapist, massage therapist, chiropractor)"
		}
	},
	"fr": {
        "title": "Vous avez besoin d’une copie de votre carte d’identification?",
        "subtitle": "Imprimez ou téléchargez une copie de votre carte ou partagez-la avec les autres personnes assurées de votre régime.",
        "label": {
            "print": "Imprimer ou télécharger la carte",
            "email": "Envoyer la carte par courriel"
        },
		"repatriation": {
			"headingMBC": "Votre carte de Croix Bleue Medavie",
			"headingSBC": "Votre carte de Croix Bleue de la Saskatchewan",
			"desc": "Veuillez présenter votre nouvelle carte d’identification ci-dessous la prochaine fois que vous visiterez votre :",
			"bulletOne": "Pharmacie",
			"bulletTwo": "Dentiste",
			"bulletThree": "Fournisseur de soins de santé complémentaires (p. ex., physiothérapie, massothérapie et chiropractie)"
		}
	}
}
</i18n>
