<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template data-tracking-id="email-input">
	<BaseInput
		ref="emailInput"
		:value="value"
		:max-length="255"
		validation-id="email_validation"
		type="email"
		name="email"
		data-vv-validate-on="blur"
		:automation-id="getAutomationId('email')"
		:validation-rules="{
			required: true,
			email: true,
			isSameEmail: { currentEmail, requiresCurrentEmailCheck }
		}"
		:validation-messages="{
			required: $t('error.emailEmpty'),
			email: $t('error.malformedEmail'),
			isSameEmail: $t('error.sameEmail')
		}"
		:label="label"
		@input="updateEmail"
		@keyup="onKeyUp"
	/>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
/**
 * This component is renders an email input field
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseInput from '@/components/common/base/BaseInput';
import { required, email } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);
extend('email', email);
//Rule to verify if the passed email is the same
extend('isSameEmail', {
	validate: (value, { currentEmail, requiresCurrentEmailCheck }) => {
		if (currentEmail && requiresCurrentEmailCheck) {
			return value !== currentEmail;
		}
		return true;
	},
	params: ['currentEmail', 'requiresCurrentEmailCheck'],
	message: 'This email is currently being used on your account.'
});

// @vue/component
@Component({
	name: 'EmailInput',
	components: {
		BaseInput
	},
	mixins: [IdMixin],
	props: {
		/**
		 * This prop is used to determine what to display above the email input field.
		 */
		label: {
			type: String,
			default: ''
		},
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		automationId: {
			type: String,
			required: true
		},
		requiresCurrentEmailCheck: {
			type: Boolean,
			default: false
		},
		currentEmail: {
			type: String,
			default: ''
		}
	}
})
export default class EmailInput extends Vue {
	data() {
		return {
			value: null,
			type: 'email'
		};
	}
	/**
	 * This function emits to the parent component the value entered into the email field.
	 */
	updateEmail(email) {
		this.value = email;
		this.$emit('input', email);
	}

	/**
	 * This function is used to force a character limit in the name input field.
	 * The maxlength is enough for most browsers, but on android devices it needs this function.
	 */
	onKeyUp() {
		const input = this.$refs.emailInput;
		const max = 255;
		if (input.value !== null && input.value.length >= max) {
			input.value = input.value.substring(0, max);
		}
	}
}
</script>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
  {
    "en": {
      "error": {
        "emailEmpty": "Please provide an email address.",
				"malformedEmail": "It seems your email is incomplete. Please re-enter your email address (example: name@domain.com).",
				"sameEmail": "This email is currently being used on your account."
	    }
	  },
	  "fr": {
	    "error": {
			  "emailEmpty": "Veuillez fournir une adresse courriel.",
				"malformedEmail": "Veuillez entrer une adresse courriel dans le bon format (p. ex. : nom@domaine.com).",
				"sameEmail": "Cette adresse courriel est déjà utilisée dans votre compte."
	    }
	  }
	}
</i18n>
