<!--
  Currently, there are 3 types of alerts: 

    - A basic alert with just a title.  This has dark colored borders, dark colored text and light colored background.
    - An alternate alert with a title and a small description.  This has black text, light colored background and only a dark colored border on the left hand side.
    - An alert with a lot of text (content).  Similar to the basic alert, but with a large section of content under the title.

  The alert supports the Bootstrap theme variants 'info', 'primary', 'danger', 'warning' and 'success'.  Using the variant prop with one of these will affect the colors of the alert.

  To display the alert using the alternate style, use the 'alternate' prop.

  Note: Styling comes from src/style/_alert.scss

  Examples:

    <base-alert variant="info" :icon="['fal', 'exclamation-circle']">
      Informational message
    </base-alert>

    <base-alert variant="primary" alternate :icon="['fal', 'exclamation-circle']">
      Message:
      <template #description>
        This is a friendly informational message
      </template>
    </base-alert>

    <base-alert variant="danger" :icon="['fal', 'exclamation-triangle']">
      Alert description goes in here
      <template #additional-content>
        Lorem ipsum dolor sit amet, linked text consectetur adipiscing elit. Fusce interdum
        tempor venenatis. Integer a tristique tellus. Proin porta nec odio vitae dictum.
        Maecenas id purus bibendum, lacinia odio in, condimentum est. Sed vehicula egestas
        ligula nec aliquam. Sed leo erat, aliquet a sodales maximus, ornare aliquam tortor.
      </template>
    </base-alert>

-->

<template>
	<BAlert :show="show" :variant="variant" :class="alternateClasses" v-bind="$attrs">
		<div class="d-flex">
			<AlertIcon :icon="icon"></AlertIcon>
			<span>
				<AlertTitle><slot></slot></AlertTitle>
				<span v-if="$slots['description']" class="alert-description">
					<slot name="description"></slot>
				</span>
			</span>
		</div>
		<div v-if="$slots['additional-content']" class="alert-additional-content">
			<slot name="additional-content"></slot>
		</div>
	</BAlert>
</template>

<script>
import { BAlert } from 'bootstrap-vue';
import alertProps from './alertProps';
import AlertIcon from './AlertIcon.vue';
import AlertTitle from './AlertTitle.vue';
export default {
	name: 'BaseAlert',
	components: {
		BAlert,
		AlertIcon,
		AlertTitle
	},
	mixins: [alertProps],
	inheritAttrs: false,
	props: {
		variant: {
			type: String,
			default: 'primary'
		}
	},
	computed: {
		/** Create alternate classes for Bootstrap theme variants.  ex: alert-alternate-primary */
		alternateClasses() {
			return this.alternate
				? `alert-alternate alert-alternate-${this.variant} responsive-padding`
				: null;
		}
	}
};
</script>

<style lang="scss" scoped>
@include media-breakpoint-down(sm) {
	.responsive-padding {
		padding-left: 15px;
	}
}
</style>
