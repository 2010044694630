<!--
  A Font Awesome icon used in alerts.
-->
<template>
	<font-awesome-icon
		v-if="icon"
		class="alert-icon"
		:icon="icon"
		aria-hidden="true"
	></font-awesome-icon>
</template>

<script>
export default {
	name: 'AlertIcon',
	props: {
		icon: {
			type: Array,
			required: false,
			default() {
				return null;
			},
			description: 'Font Awesome array notation.'
		}
	}
};
</script>
