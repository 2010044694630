<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<BaseCard automation-id="shareMemberCard">
		<p class="h1 title">{{ $t('title') }}</p>
		<p class="h2 subtitle">{{ $t('subtitle') }}</p>
		<BaseForm
			ref="authentication-form"
			:data-tracking-id="getAutomationId('forgotPasswordForm')"
			:automation-id="getAutomationId('forgotPasswordForm')"
			@submit="sendEmails"
		>
			<div v-for="(email, index) in emailList" :key="index" class="emailList">
				<EmailInput
					ref="emailRef"
					:value="email"
					:label="$t('label.email')"
					class="input-width"
					:automation-id="getAutomationId(`shareMemberCard${index}`)"
					@input="saveEmail(index, $event)"
				/>

				<BaseButton
					v-if="index > 0"
					:id="getUniqueId('modalButton')"
					ref="triggerButton"
					class="removeEmailBtn"
					:pill="false"
					variant="link"
					:automation-id="getAutomationId(`shareMemberCardRemove${index}`)"
					:icon="['fal', 'times-circle']"
					type="button"
					@click="removeEmail(index)"
				/>
			</div>
			<BaseButton
				v-if="emailList.length < 8"
				:label="$t('label.add')"
				:aria-label="$t('label.add')"
				:icon="['fal', 'plus-circle']"
				icon-position="left"
				:pill="false"
				variant="link"
				class="mb-4 pt-0 pl-0"
				:automation-id="getAutomationId('shareMemberCardAdd')"
				:data-tracking-id="getAutomationId('shareMemberCardAdd')"
				@click="addEmail"
			/>
			<br />
			<BaseButton
				:label="$t('label.sendCard')"
				class="m-0 sendCardBtn"
				:aria-label="$t('label.sendCard')"
				variant="primary"
				:automation-id="getAutomationId('shareMemberCardSend')"
				:data-tracking-id="getAutomationId('shareMemberCardSend')"
				type="submit"
			/>

			<BaseButton
				:label="$t('label.cancel')"
				:pill="false"
				variant="link"
				class="cancelBtn mt-0 pt-0 pl-0"
				:automation-id="getAutomationId('shareMemberCardCancel')"
				:data-tracking-id="getAutomationId('shareMemberCardCancel')"
				@click="navigateToMemberCard()"
			/>
		</BaseForm>
	</BaseCard>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
// This component renders the share Email functionality.
// A member is able to add emails to a list to be sent a member card, or possibly other functionality.
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import * as Routes from '@/constants/Routes.js';
import BaseForm from '@/components/common/base/BaseForm';
import EmailInput from '@/components/common/EmailInput';
import BaseButton from '@/components/common/base/BaseButton';
import BaseCard from '@/components/common/card/BaseCard';
/**
 * This component is responsible to render a member table.
 */
// @vue/component
@Component({
	name: 'ShareMemberCard',
	components: {
		EmailInput,
		BaseButton,
		BaseForm,
		BaseCard
	},
	mixins: [IdMixin]
})
export default class ShareMemberCard extends Vue {
	emailList = [''];

	// Add a new empty value to the array to show another email input field. Only 8 emails inlcuding the default one can be added.
	addEmail() {
		if (this.emailList.length < 8) {
			this.emailList.push('');
		}
		this.$nextTick(() => {
			//This will move the focus to the new component
			this.$refs.emailRef[
				this.emailList.length - 1
			].$el.children[0].children[1].children[1].focus();
		});
	}

	// Update the email list with the new value, Have to use Vue.set() to actually have vue know the array updated.
	saveEmail(index, value) {
		Vue.set(this.emailList, index, value);
	}

	// Remove an email from the list.
	removeEmail(index) {
		this.emailList.splice(index, 1);
		this.$nextTick(() => {
			//This will put the focus at the end or in the middle
			if (index > this.emailList.length - 1) {
				this.$refs.emailRef[
					this.emailList.length - 1
				].$el.children[0].children[1].children[1].focus();
			} else {
				this.$refs.emailRef[index]?.$el.children[0].children[1].children[1].focus();
			}
		});
	}

	// Emit the list of emails to the parent component.
	sendEmails() {
		this.$emit('submit', this.emailList);
	}

	navigateToMemberCard() {
		this.$router.push({ name: Routes.MEMBER_CARD });
	}
}
</script>

<style lang="scss" scoped>
.title {
	font-size: $header-main-font-size;
	margin-bottom: 0;
	@media (max-width: 500px) {
		line-height: 1.3em;
	}
}
.subtitle {
	font-size: $header-sub-font-size;
	font-family: $body-small-font-family;
	font-weight: $body-small-font-weight;
	margin-bottom: 25px;
}
.emailList {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.input-width {
	width: 45%;
	@media (max-width: 600px) {
		width: 80%;
	}
}
.removeEmailBtn {
	margin-top: 25px;
	font-size: 1.35em;
	margin-left: -10px;
	height: 50px;
}
.cancelBtn {
	margin-left: 35px;
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
	"en": {
        "title": "Share your member card via email",
        "subtitle": "We’ll send a copy of your member card to the email address you enter below.",
        "label": {
            "email": "Email to",
            "add": "Add another email",
            "sendCard": "Send Card",
            "cancel": "Cancel"
        }
	},
	"fr": {
        "title": "Envoyer votre carte d’identification par courriel",
        "subtitle": "Nous vous enverrons une copie de votre carte d’identification à l’adresse courriel indiquée ci-dessous.",
        "label": {
            "email": "Envoyer à l'adresse suivante",
            "add": "Ajouter une autre adresse courriel",
            "sendCard": "Envoyer la carte",
            "cancel": "Annuler"
        }
	}
}
</i18n>
