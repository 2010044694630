<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<ManageYourAccountTemplate :page-title="$t('title')">
		<ShareMemberCard
			id="shareMemberCard"
			:automation-id="shareMemberCardPage"
			@submit="sendEmails"
		/>
	</ManageYourAccountTemplate>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import MemberCardInfo from '@/models/MemberCardInfo.js';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import * as Routes from '@/constants/Routes.js';
import ShareMemberCard from '@/components/manage-your-account/member-card/ShareMemberCard';
import ManageYourAccountTemplate from '../ManageYourAccountTemplate.vue';

/**
 * This component is responsible to render a member table.
 */
// @vue/component
@Component({
	name: 'ShareMemberCardPage',
	components: {
		ShareMemberCard,
		ManageYourAccountTemplate
	},
	mixins: [BreadcrumbsManager]
})
export default class ShareMemberCardPage extends Vue {
	created() {
		// Set the path for the BreadCrumbs Manager.
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: Routes.DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'en'), to: { name: Routes.MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.memberCard', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: Routes.DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'fr'), to: { name: Routes.MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.memberCard', 'fr') }
			]
		);
	}

	/**
	 * Method to call the MemberCard Info model to send out member cards to the emails
	 * the member entered in the list.
	 * Display a loading modal, and an error modal if something goes wrong.
	 */
	sendEmails(emails) {
		this.$store.dispatch('updateLoading', true);
		this.loading = true;
		MemberCardInfo.shareMemberCard(
			emails,
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)
			.then((response) => {
				if (!response && sessionStorage.getItem('demoActive')) {
					this.$store.dispatch('successBanner', { show: true, text: this.$t('success') });
				} else if (response && (response.status === 201 || response.status === 200)) {
					this.$store.dispatch('successBanner', { show: true, text: this.$t('success') });
				} else if (response && response.status > 400) {
					if (response.data && response.data.message) {
						let header = response.data.title;
						if (!header) {
							header = this.$t('error.wentWrongHeader');
						}
						this.$store.dispatch('error', {
							name: 'share-card-error',
							title: header,
							err: response.data.message
						});
					} else {
						this.$store.dispatch('error', {
							name: 'share-card-error',
							title: this.$t('error.wentWrongHeader'),
							err: this.$t('error.wentWrong')
						});
					}
				} else {
					this.$store.dispatch('error', {
						name: 'share-card-error',
						title: this.$t('error.wentWrongHeader'),
						err: this.$t('error.wentWrong')
					});
				}
			})
			.finally(() => {
				this.$store.dispatch('updateLoading', false);
				this.loading = false;
			});
	}
}
</script>

<style lang="scss" scoped>
.shareMemberCard {
	margin-left: 5%;
	@media (max-width: 500px) {
		margin-left: 0;
	}
	width: 67%;
	padding: 30px;
	@media (max-width: 600px) {
		width: 100%;
	}
	@media (max-width: 767px) {
		padding: 15px;
	}
}
.formBody {
	width: 60em;
	margin: 0;
	@media (max-width: 768px) {
		width: 100%;
	}
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
	"en": {
        "title": "Member Card",
		"breadcrumb": {
			"home": "Home",
			"memberInfo": "Manage your account",
			"memberCard": "Member card"
		},
		"success": "Your member card has been sent.",
		"error": {
			"wentWrongHeader": "Something went Wrong",
			"wentWrong": "Oops, something went wrong. Please try again later."
		}
	},
	"fr": {
        "title": "Carte d’identification",
		"breadcrumb": {
			"home": "Accueil",
			"memberInfo": "Gérer votre compte",
			"memberCard": "Carte d’identification"
		},
		"success": "Votre carte d’identification a été envoyée.",
		"error": {
			"wentWrongHeader": "Une erreur s’est produite",
			"wentWrong": "Nous sommes désolés, une erreur s’est produite. Veuillez réessayer plus tard."
		}

	}
}
</i18n>
