import { mapMutations } from 'vuex';
let breadcrumbPathEn = null;
let breadcrumbPathFr = null;
export default {
	async mounted() {
		this.emptyBreadcrumbs();
		if (this.$store.state.i18n.locale === 'fr') {
			this.setBreadcrumbs(breadcrumbPathFr);
		} else {
			this.setBreadcrumbs(breadcrumbPathEn);
		}
	},
	async updated() {
		this.emptyBreadcrumbs();
		if (this.$store.state.i18n.locale === 'fr') {
			this.setBreadcrumbs(breadcrumbPathFr);
		} else {
			this.setBreadcrumbs(breadcrumbPathEn);
		}
	},
	async destroyed() {
		this.emptyBreadcrumbs();
	},
	methods: {
		...mapMutations('breadcrumbModule', {
			setBreadcrumbs: 'set',
			pushBreadcrumb: 'push',
			popBreadcrumb: 'pop',
			replaceBreadcrumb: 'replace',
			emptyBreadcrumbs: 'empty'
		}),
		setBreadcrumbPath(linksEn, linksFr) {
			breadcrumbPathEn = linksEn;
			breadcrumbPathFr = linksFr;
		}
	}
};
