<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<BRow>
		<BCol md="6">
			<BaseCard :automation-id="getAutomationId('memberCardInfo')">
				<Logo class="logo" />
				<div v-if="memberInfo.cardholder" class="memberInfo">
					<p class="name">
						{{ memberInfo.cardholder.firstName }} {{ memberInfo.cardholder.lastName }}
					</p>
					<div class="numberHeading">{{ $t('label.identification') }}</div>
					<div class="bigNumbers">{{ memberInfo.cardholder.idNumber }}</div>
					<div class="numberHeading">{{ $t('label.policy') }}</div>
					<div class="bigNumbers">{{ memberInfo.cardholder.policyNumber }}</div>
				</div>
			</BaseCard>
		</BCol>
		<BCol md="6">
			<BaseCard :automation-id="getAutomationId('memberCardInfo')" class="otherMembers">
				<div class="topInfo">
					<div class="othersCoveredLabel">{{ $t('label.othersCovered') }}</div>
					<img
						class="logo backLogo"
						:alt="$t('logo')"
						:src="require('@/assets/bluecross-blueshield-logo.svg')"
						:automation-id="getAutomationId('cardInfoImage')"
					/>
				</div>
				<BTable
					class="table"
					primary-key="id"
					:items="memberInfo.othersOnPlanList"
					:fields="getFields()"
					:automation-id="getAutomationId('cardInfo')"
				/>
			</BaseCard>
		</BCol>
	</BRow>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
// This component renders the share Email functionality.
// A member is able to add emails to a list to be sent a member card, or possibly other functionality.
import Vue from 'vue';
import Component from 'vue-class-component';
import { BTable, BRow, BCol } from 'bootstrap-vue';
// mixins
import IdMixin from '@/mixins/id';
// components
import BaseCard from '@/components/common/card/BaseCard';
import Logo from '@/components/common/Logo.vue';

/**
 * This component is responsible to render a member table.
 */
// @vue/component
@Component({
	name: 'MemberCardInfo',
	components: {
		BTable,
		BaseCard,
		BRow,
		BCol,
		Logo
	},
	mixins: [IdMixin],
	props: {
		memberInfo: {
			type: Object,
			default: null
		}
	}
})
export default class MemberCardInfo extends Vue {
	/**
	 * Returns the list of columns
	 * @returns {array}
	 */
	getFields() {
		return [
			{
				key: 'name',
				label: this.$t('column.name')
			},
			{
				key: 'idNumber',
				label: this.$t('column.idNumber')
			}
		];
	}
}
</script>

<style lang="scss" scoped>
* ::v-deep .card-body {
	padding-top: 0;
	padding-bottom: 0;
	height: auto;
	overflow: hidden;
}
.topInfo {
	display: flex;
}
.otherMembers {
	min-height: 260px;
}
.logo {
	height: 30px;
	margin: 30px 0 0 0;
}
.backLogo {
	margin-left: auto;
	height: 21px;
}
.name {
	font-size: 21px;
	font-weight: bold;
	margin-top: 21px;
	font-family: $josefin-font-family;
}

.collect {
	font-size: 0.7em;
	font-style: italic;
	margin-left: 7em;
}
.numberHeading {
	font-size: 15px;
	font-weight: bold;
	font-family: $josefin-font-family;
}
.othersCoveredLabel {
	margin-top: 30px;
	font-family: $josefin-font-family;
	font-size: 18px;
}
.bigNumbers {
	font-size: 15px;
	margin-bottom: 20px;
	font-family: $body-small-font-family;
}
.memberInfo {
	margin-bottom: 30px;
}
// CSS for the BaseTable
.table {
	margin-top: 23px;
}

.table ::v-deep td {
	font-size: 15px;
	font-family: $body-small-font-family;
	padding: 0.25rem;
	border: none;
}
.table ::v-deep td div {
	border: none;
}

.table ::v-deep thead th {
	border: none;
	padding: 0.25rem;
}
.table ::v-deep thead th div {
	font-size: 15px;
	font-family: $josefin-font-family;
}
.table ::v-deep td:nth-child(1) {
	width: 50%;
	@media (max-width: 320px) {
		width: 70%;
	}
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
  "en": {
    "logo": "Blue Cross",
    "label": {
      "identification": "Identification Number",
      "policy": "Policy Number",
      "othersCovered": "Others covered by this plan"
    },
    "column": {
      "idNumber": "Identification Number",
      "name": "Name"
    }
  },
  "fr": {
    "logo": "Croix Bleue",
    "label": {
      "identification": "Numéro d'identifcation",
      "policy": "Numéro de police",
      "othersCovered": "Autres personnes couvertes par ce régime"
    },
    "column": {
      "idNumber": "Numéro d'identification",
      "name": "Nom"
    }
  }
}
</i18n>
