export default {
	props: {
		show: {
			type: Boolean,
			required: false,
			default: true,
			description: 'Alert show or hide'
		},
		variant: {
			type: String,
			required: false,
			default: 'primary',
			validator: (propValue) => {
				return ['info', 'primary', 'danger', 'warning', 'success'].includes(propValue);
			},
			description: 'Alert variant, based on Bootstrap theme color variants'
		},
		alternate: {
			type: Boolean,
			required: false,
			default: false,
			description: 'Set to true to display an alert with the alternate style'
		},
		icon: {
			type: Array,
			required: false,
			default() {
				return null;
			},
			description: 'Font Awesome array notation.'
		}
	}
};
