<!--
-->
<template>
	<div class="alert-title">
		<strong><slot></slot></strong>
	</div>
</template>

<script>
export default {
	name: 'AlertTitle'
};
</script>
