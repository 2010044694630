<template>
	<div class="mt-n2 d-flex flex-wrap justify-content-around justify-content-sm-start">
		<div>
			<!-- Apple Store -->
			<a :automation-id="getAutomationId('mobile_app_link')" :href="appStoreUrl" target="_blank">
				<img class="mt-2 pr-0 pr-sm-3" :src="appStoreSrc" :alt="$t('downloadAppStore')" />
			</a>
		</div>
		<div>
			<!-- Google Play -->
			<a :automation-id="getAutomationId('mobile_app_link')" :href="playUrl" target="_blank">
				<img class="mt-2" :src="playSrc" :alt="$t('downloadPlayStore')" />
			</a>
		</div>
	</div>
</template>

<script>
/**
 * This component displays a the links to download the mobile app.
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
// @vue/component
@Component({
	name: 'MobileAppLinks',
	mixins: [IdMixin]
})
export default class MobileAppLinks extends Vue {
	securityPolicy = sessionStorage.getItem('securityPolicy');
	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	get appStoreUrl() {
		if (this.securityPolicy) {
			if (JSON.parse(this.securityPolicy).brand === 'SBC') {
				return this.$store.state.i18n.locale === 'en'
					? 'https://apps.apple.com/ca/app/sk-blue-cross-group/id954993959?l=en'
					: 'https://apps.apple.com/ca/app/sk-blue-cross-group/id954993959?l=fr';
			}
		}
		return this.$store.state.i18n.locale === 'en'
			? 'https://apps.apple.com/ca/app/medavie-blue-cross-mobile/id674013263?l=en'
			: 'https://apps.apple.com/ca/app/croix-bleue-medavie-mobile/id674013263?l=fr';
	}

	get appStoreSrc() {
		return this.$store.state.i18n.locale === 'en'
			? require('@/assets/Download_on_the_App_Store_Badge_EN.svg')
			: require('@/assets/Download_on_the_App_Store_Badge_FR.svg');
	}

	get playUrl() {
		if (this.securityPolicy) {
			if (JSON.parse(this.securityPolicy).brand === 'SBC') {
				return this.$store.state.i18n.locale === 'en'
					? 'https://play.google.com/store/apps/details?id=ca.skbluecross.mobile&hl=en_CA'
					: 'https://play.google.com/store/apps/details?id=ca.skbluecross.mobile&hl=fr_CA';
			}
		}
		return this.$store.state.i18n.locale === 'en'
			? 'https://play.google.com/store/apps/details?id=ca.medavie.mobile&hl=en_CA'
			: 'https://play.google.com/store/apps/details?id=ca.medavie.mobile&hl=fr_CA';
	}

	get playSrc() {
		return this.$store.state.i18n.locale === 'en'
			? require('@/assets/google-play-badge-en.png')
			: require('@/assets/google-play-badge-fr.png');
	}
}
</script>

<style lang="scss" scoped>
img {
	max-height: 60px;
	max-width: 100%;
	// fixes PNG from going blurry
	image-rendering: -moz-crisp-edges; /* Firefox */
	image-rendering: -o-crisp-edges; /* Opera */
	image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
	image-rendering: crisp-edges;
	-ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
</style>

<i18n>
{
	"en": {
		"downloadAppStore": "Download on the App Store",
		"downloadPlayStore": "Get it on Google Play"
	},
	"fr": {
		"downloadAppStore": "Télécharger dans l'App Store",
		"downloadPlayStore": "Disponible sur Google Play"
	}
}
</i18n>
