<!--
  Page template to be use for all Manage Your Account pages.
-->
<template>
	<div class="margin-top">
		<BRow v-if="pageTitle">
			<BCol>
				<h1 class="mb-3">{{ pageTitle }}</h1>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="12" :lg="fullWidth ? '12' : '10'">
				<slot></slot>
			</BCol>
		</BRow>
	</div>
</template>

<script>
import IdMixin from '@/mixins/id';
import { BRow, BCol } from 'bootstrap-vue';

export default {
	name: 'ManageYourAccountTemplate',
	components: { BRow, BCol },
	mixins: [IdMixin],

	props: {
		pageTitle: {
			type: String,
			description: 'Top of page title',
			default: null
		},
		fullWidth: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="scss" scoped>
.margin-top {
	margin-top: 15px !important;
	@media (min-width: 768px) {
		margin-top: 30px !important;
	}
}
</style>
