<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<ManageYourAccountTemplate :page-title="$t('title')">
		<BRow>
			<BCol>
				<MemberCard
					id="MemberCard"
					:member-info="memberInfo"
					automation-id="memberCardPage"
					@submit="getMemberCardPdf()"
				/>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<BaseCard :title="$t('mobile.title')" title-class="h4" automation-id="memberCardPage">
					<BCardText>
						<p>{{ $t('mobile.subtitle') }}</p>
					</BCardText>
					<MobileAppLinks />
				</BaseCard>
			</BCol>
		</BRow>
	</ManageYourAccountTemplate>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol, BCardText } from 'bootstrap-vue';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import * as Routes from '@/constants/Routes.js';
import MemberCardInfo from '@/models/MemberCardInfo.js';
import MemberCard from '@/components/manage-your-account/member-card/MemberCard';
import MobileAppLinks from '@/components/common/MobileAppLinks';
import BaseCard from '@/components/common/card/BaseCard';
import ManageYourAccountTemplate from '@/pages/manage-your-account/ManageYourAccountTemplate.vue';

/**
 * This component is responsible to render a member table.
 */
// @vue/component
@Component({
	name: 'MemberCardPage',
	components: {
		BRow,
		BCol,
		BaseCard,
		MemberCard,
		MobileAppLinks,
		ManageYourAccountTemplate,
		BCardText
	},
	mixins: [BreadcrumbsManager],
	watch: {
		locale() {
			this.getMemberCardInfo();
		}
	}
})
export default class MemberCardPage extends Vue {
	memberInfo = {};

	// Computed property to watch if the locale changes.
	get locale() {
		return this.$root.$i18n.locale;
	}

	/**
	 * before creating the component call the API to get the information to display on the member card.
	 */
	created() {
		this.getMemberCardInfo();
		// Set the path for the BreadCrumbs Manager.
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: Routes.DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'en'), to: { name: Routes.MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.memberCard', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: Routes.DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'fr'), to: { name: Routes.MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.memberCard', 'fr') }
			]
		);
	}

	getMemberCardInfo() {
		this.$store.dispatch('updateLoading', true);
		this.loading = true;
		MemberCardInfo.getMemberCardDetails(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)
			.then((response) => {
				if (response && (response.status === 201 || response.status === 200)) {
					this.memberInfo = response.data;
					// Need to add this in for accessiblity,
					if (!this.memberInfo.othersOnPlanList) {
						this.memberInfo.othersOnPlanList = [
							{
								idNumber: '',
								name: ''
							}
						];
					}
				} else {
					this.$store.dispatch('error', {
						name: 'member-card-error',
						title: this.$t('error.wentWrongHeader'),
						err: this.$t('error.wentWrong')
					});
				}
			})
			.finally(() => {
				this.$store.dispatch('updateLoading', false);
				this.loading = false;
			});
	}

	/**
	 * Method calls the Api to get the member card pdf,
	 * Then calls another method to download it for the member.
	 */
	getMemberCardPdf() {
		this.$store.dispatch('updateLoading', true);
		this.loading = true;
		MemberCardInfo.getMemberCardPdf(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)
			.then((response) => {
				if (response && (response.status === 201 || response.status === 200)) {
					MemberCardInfo.downloadMemberCardPdf(response.data);
				} else {
					this.$store.dispatch('error', {
						name: 'member-card-error',
						title: this.$t('error.wentWrongHeader'),
						err: this.$t('error.wentWrong')
					});
				}
			})
			.finally(() => {
				this.$store.dispatch('updateLoading', false);
				this.loading = false;
			});
	}
}
</script>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
	"en": {
        "title": "Member Card",
		"breadcrumb": {
			"home": "Home",
			"memberInfo": "Manage your account",
			"memberCard": "Member card"
		},
		"error": {
			"wentWrongHeader": "Something went Wrong",
			"wentWrong": "Oops, something went wrong. Please try again later."
		},
		"mobile": {
			"title": "Want to share your card another way or save it to your phone's wallet?",
			"subtitle": "You can access a digital version of your card using the mobile app (available for IOS and Android)."
		}
	},
	"fr": {
        "title": "Carte d'identification",
		"breadcrumb": {
			"home": "Accueil",
			"memberInfo": "Gérer votre compte",
			"memberCard": "Carte d'identification"
		},
		"error": {
			"wentWrongHeader": "Une erreur s'est produite",
			"wentWrong": "Nous sommes désolés, une erreur s'est produite. Veuillez réessayer plus tard."
		},
		"mobile": {
			"title": "Vous voulez partager votre carte d'une autre façon ou l'enregistrer dans votre portefeuille mobile?",
			"subtitle": "Vous pouvez accéder à la version numérique de votre carte d'identification dans l'application mobile (offerte pour les appareils iOS et Android)."
		}

	}
}
</i18n>
